import React,{ useEffect,useMemo,useState } from 'react';
import cls from "./styles.module.scss";
import { EmployeesTable } from "./components/employee-table/employeesTable";
import { observer } from "mobx-react-lite";
import { useStore } from "store";
import { getEmployees } from 'api/accessRights';
import { showNotificationModal } from 'ui-new/alert';

export const AccessRights = observer(() => {

  const { RootStore } = useStore();
  const { RoleStore } = RootStore;
  const { users,setUsers } = RoleStore;
  const { HeaderStore: { profileData } } = RootStore;

  const [isLoading,setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    getEmployees().then(res => {
      if (!res) throw new Error('Произошла ошибка');
      setUsers(res);
      setIsLoading(false);
    }).catch();
  },[RoleStore,setUsers]);

  const canAccessRightsView = useMemo(() => {
    if (!profileData) return false;

    const job = profileData.job_title_en;
    if (job !== 'Upravlyayushtiy') return false;

    return true;
  },[profileData]);

  useEffect(() => {
    if (!canAccessRightsView) {
      showNotificationModal({ title: 'Ошибка прав доступа',errors: ['Не соответствие прав доступа, невозможно выполнить операцию'],type: 'failure' });
    }
  },[canAccessRightsView]);

  if (!canAccessRightsView) {

    return null;
  }

  return (
    <div className={cls.accessRightsPage}>
      <h2>Команда</h2>
      <EmployeesTable isLoading={isLoading} users={users} />
    </div>
  );
});
