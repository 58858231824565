import React, {useEffect, useState} from 'react';
import styles from './styles.module.scss';
import {Button} from "ui-new/button";
import {type TabButtonItem, Tabs} from "ui-new/tabs";
import {DataTable, type DataTableHeader} from "ui-new/table";
import {closeLoan, getLoansList} from "api/loan";
import type {Loan, LoanPosition} from "../../types/loan";
import dayjs from "dayjs";
import { status } from 'utils/const/loan/status';
import {useNavigate} from "react-router";
import {expandHeaders, headers, TAB_ITEMS} from "utils/const/loan/loans";
import {showAlertPopup} from "../../ui/alert";
import type {Nullable} from "../../assets/types";
import {ConfirmModal} from "../../widgets/modal/confirm-modal";

export const LoanPage = () => {
    const [currentTab, setCurrentTab] = useState(TAB_ITEMS[0]);
    const [isTableLoading, setTableLoading] = useState<boolean>(false);
    const [tableInItems, setTableInItems] = useState<Loan[]>([]);
    const [tableOutItems, setTableOutItems] = useState<Loan[]>([]);
    const [currentOutLoanId, setCurrentOutLoanId] = useState<Nullable<number | string>>(null);
    const [isConfirmModal, setConfirmModal] = useState(false);

    const navigate = useNavigate();

    const onTabHandler = (item: TabButtonItem) => {
        setCurrentTab(() => ({ ...item, }));
    }

    const closeOwnLoan = async (itemId: number | string) => {
        const response = await closeLoan({ id: itemId });

        if (!response) {
            showAlertPopup('Ошибка!', 'Не удалось закрыть займ', 'error');
            return;
        }

        showAlertPopup('Успешно!', 'Займ успешно закрыт!', 'success');
        await initPageData();
    }

    const onConfirmModalOpen = (loanId: number | string) => {
        setCurrentOutLoanId(loanId);
        setConfirmModal(true);
    }

    const onLoanHandler = async () => {
        if (!currentOutLoanId) {
            return;
        }

        await closeOwnLoan(currentOutLoanId);
        setConfirmModal(false);
    }

    const tableCell = (item: Loan, header: DataTableHeader<Loan>) => {
        switch (header.value) {
            case "created_at":
                return <span className={styles.cellText}>
                    {item[header.value] ? dayjs(item[header.value]).format("DD.MM.YYYY") : '—'}
                </span>
            case "closed_at":
                return <span className={styles.cellText}>
                    {item[header.value] ? dayjs(item[header.value]).format("DD.MM.YYYY") : '—'}
                </span>
            case "status":
                return <span className={styles.cellText}>{status[item[header.value]]}</span>
            case "sum":
            case "sum_fact":
            case "to_repay":
                return <span className={styles.cellText}>
                    {new Intl.NumberFormat('ru-RU').format(item[header.value])}
                </span>

            default:
                return <span className={styles.cellText}>
                    {item[header.value] as any}
                </span>
        }
    }

    const expandTableCell = (item: LoanPosition, header: DataTableHeader<LoanPosition>) => {
        switch (header.value) {
            case "status":
                return <span className={styles.cellText}>{status[item[header.value]]}</span>

            default:
                return <span className={styles.cellText}>
                    {item[header.value]}
                </span>
        }
    }

    const expandContent = (item: Loan) => {
        return (<div className={styles.expandContainer}>
            <DataTable
                itemKey="id"
                items={item.positions}
                headers={expandHeaders}
                children={(item, header) => expandTableCell(item, header)}
            />
            {currentTab.id === 1 && <Button onClick={() => toLoan(item.id)} className={styles.closeLoanButton}>Вернуть</Button>}
            {currentTab.id === 2 && item.status !== 'close' &&
                <Button
                    disabled={item.status === 'close'}
                    onClick={() => onConfirmModalOpen(item.id)}
                    className={styles.closeLoanButton}
                >
                    Закрыть реализацию
                </Button>}
        </div>)
    }

    const tableData = () => {
        if (currentTab.id === 1) {
            return tableInItems;
        } else if (currentTab.id === 2) {
            return tableOutItems;
        }

        return [];
    }

    const tableHeaders = () => headers.map(header => ({ ...header, textAlign: 'left' }));

    const toLoan = (loanId: number) => {
        navigate(`/main/loan/edit/${loanId}`);
    }

    const initPageData = async () => {
        setTableLoading(true);

        getLoansList()
            .then((result) => {
                if (result) {
                    setTableInItems([...result?.in]);
                    setTableOutItems([...result?.out]);
                }
            })
            .catch(err => {})
            .finally(() => {
                setTableLoading(false);
            })
    };

    useEffect(() => {
        initPageData();
    }, []);

    return (
        <div className={styles.page}>
            <header className={styles.pageHeader}>
                <h1 className={styles.pageHeaderText}>Реализации между спотами</h1>

                <Button onClick={() => navigate('/main/loan/create')}>Оформить реализацию</Button>
            </header>

            <div className={styles.tabsContainer}>
                <Tabs currentTab={currentTab} items={TAB_ITEMS} onTabHandler={onTabHandler} />
            </div>

            <div className={styles.tableWrapper}>
                <div className={styles.filters}>
                    {/*  TODO: Add filter for dates here for table  */}
                </div>

                <DataTable
                    itemKey="id"
                    headers={tableHeaders() as any}
                    items={tableData()}
                    children={(item, header) => tableCell(item, header)}
                    expandContent={(item) => expandContent(item)}
                    isLoading={isTableLoading}
                    withExpand
                />

                {isConfirmModal && <ConfirmModal
                    onClose={() => setConfirmModal(false)}
                    onConfirm={onLoanHandler}
                    title='Закрыть?'
                    messages={['Вы точно уверены, что хотите закрыть реализацию?']}
                    confirmButtonLabel='Да'
                />}
            </div>
        </div>
    );
};