import { observer } from 'mobx-react-lite';
import { FC,useMemo } from 'react';
import cls from './table-styles.module.scss';
import { useStore } from 'store';
import { IOwner } from '../types';
import { toJS } from 'mobx';

interface TableProps {

}

const TableComponent: FC<TableProps> = () => {
  const { RootStore: { SpotPageStore: { owners } } } = useStore();

  const partnersData = useMemo(() => {
    if (!owners.length)
      return <tr>
        <td colSpan={3}>
          <div>
            <p>
              Фин. партнеры не найдены
            </p>
          </div>
        </td>
      </tr>;

    return toJS(owners).map((p,i) => <Partner index={i + 1} partner={p} />);
  },[owners]);

  return (
    <table className={cls['table']}>
      <colgroup>
        <col width={50} />
        <col width={250} />
        <col width={150} />
      </colgroup>
      <thead className={cls['thead']}>
        <tr className={cls['tr']}>
          <th className={cls['th']}>№</th>
          <th className={cls['th']}>Финпартнер</th>
          <th className={cls['th']}>Доля, 100%</th>
        </tr>
      </thead>
      <tbody className={cls['tbody']}>
        {partnersData}
      </tbody>
    </table>
  );
};

interface PartnerProps {
  partner: IOwner;
  index: number;
}

const Partner: FC<PartnerProps> = ({ partner: { dividends,full_name },index }) => {
  return <tr className={cls['tr']}>
    <td className={`${cls['td']} ${cls['num']}`}>{index}</td>
    <td className={cls['td']}>{full_name}</td>
    <td className={cls['td']}>{Math.round(dividends * 100 * 100) / 100}%</td>
  </tr>;
};

export const DividendsTable = observer(TableComponent);