import { makeAutoObservable } from "mobx";
import { RootStore } from "../../store/rootStore";
import {
  getContragents,
  getDeals,
  IDealUpdateProps,
  updateDeal,
} from "api/contracts";
import { IContragent, IDeal } from "./types";
import { showNotificationModal } from "ui-new/alert";
import { showConfirmModal } from "ui-new/alert/confirm";

export class ContractsStore {
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
  }

  deals: IDeal[] = [];
  contragents: IContragent[] = [];

  fetchGetDeals = async () => {
    const response = await getDeals();

    if (!response) return;

    this.deals = response;
  };

  fetchGetContragents = async () => {
    const response = await getContragents();

    if (!response) return;

    this.contragents = response;
  };

  updateDealConfirm = (args: IDealUpdateProps, onConfirm: () => void) => {
    showConfirmModal({
      title: "Добавить новый договор?",
      messages: [
        "После добавления договора его редактирование будет недоступно",
      ],
      confirmButtonLabel: "Да, добавить",
      onConfirm: () =>
        this.fetchUpdateDeal(args).then(resp => resp && onConfirm()),
      minWidth: "397px",
      maxWidth: "397px",
    });
  };

  fetchUpdateDeal = async (args: IDealUpdateProps) => {
    const response = await updateDeal(args);

    if (!response) return null;

    showNotificationModal({ title: "Договор добавлен", type: "success" });
    this.fetchGetDeals();
    this.fetchGetContragents();

    return true;
  };
}
