import { observer } from 'mobx-react-lite';
import { FC,useEffect,useState } from 'react';
import cls from '../styles.module.scss';
import { DividendsTable } from './table';
import { useStore } from 'store';
import { EditDividendsModal } from './edit-dividends-modal';

interface DividendsProps {

}

const DividendsComponent: FC<DividendsProps> = () => {
  const { RootStore: { SpotPageStore: { fetchGetOwners },HeaderStore: { profileData } } } = useStore();
  const [editModalIsOpen,setEditModalIsOpen] = useState(false);

  useEffect(() => {
    fetchGetOwners();
  },[fetchGetOwners,profileData]);

  return (
    <div className={cls['spot__container']}>
      <h4 className={cls['spot__subtitle']}>Распределение долей фин. партнеров</h4>
      <DividendsTable />
      <div>
        <button onClick={() => setEditModalIsOpen(true)} className={cls['button-primary']}>Изменить</button>
      </div>
      {editModalIsOpen && <EditDividendsModal onClose={() => setEditModalIsOpen(false)} />}
    </div>
  );
};

export const Dividends = observer(DividendsComponent);