import { observer } from 'mobx-react-lite';
import cls from './table-style.module.scss';
import { useStore } from 'store';
import { useMemo } from 'react';

enum PaymentType {
  prepayment = 'Предоплата',
  postpayment = 'Отсрочка платежа'
}

const TableComponent = () => {
  const { RootStore: { ContractsStore: { deals } } } = useStore();

  const dealsData = useMemo(() => {
    if (deals.length === 0) {
      return <tr>
        <td colSpan={4} className={cls['td']} style={{ height: '64px' }}>Договоры не найдены</td>
      </tr>;
    }

    return deals.map(d =>
      <tr key={d.id} className={cls['tr']}>
        <td className={cls['td']} style={{ textAlign: 'left' }}>
          <p>
            {d.contragent_title}
          </p>
          <span>ИНН {d.contragent_inn}</span>
        </td>
        <td className={cls['td']} style={{ textAlign: 'left' }}>{d.title}</td>
        <td className={cls['td']} style={{ textAlign: 'left' }}>{PaymentType[d.type]}</td>
        <td className={cls['td']} style={{ textAlign: 'left',paddingLeft: '25px' }}>{d.days} дней</td>
      </tr>
    );
  },[deals]);

  return (
    <table className={cls['table']}>
      <colgroup>
        <col width={230} />
        <col width={160} />
        <col width={154} />
        <col width={164} />
      </colgroup>
      <thead className={cls['thead']}>
        <tr className={cls['tr']}>
          <th className={cls['th']}>Поставщик</th>
          <th className={cls['th']}>№ Договора</th>
          <th className={cls['th']}>Условие оплаты</th>
          <th className={cls['th']}>Отсрочка платежа</th>
        </tr>
      </thead>
      <tbody className={cls['tbody']}>
        {dealsData}
      </tbody>
    </table>
  );
};

export const Table = observer(TableComponent);