import { observer } from 'mobx-react-lite';
import { FC,useCallback,useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useStore } from 'store';
import { EInputStyleVariant,Input } from 'ui-new/input';
import SelectField from 'ui-new/select';
import { Modal } from 'widgets/modal';
import { ModalButtons } from 'widgets/modal/buttons';
import cls from './styles.module.scss';
import { toJS } from 'mobx';
import * as yup from 'yup';
import { ValidationMessages,ValidationTypes } from 'assets/utils/validationMessages';
import { yupResolver } from '@hookform/resolvers/yup';

const schema = yup.object({
  contragent_id: yup.number().typeError(ValidationMessages.get(ValidationTypes.required) as string).required(ValidationMessages.get(ValidationTypes.required)),
  type: yup.string().oneOf(['prepayment','postpayment']).required(ValidationMessages.get(ValidationTypes.required)),
  days: yup.number().typeError('Введите корректное значение от 0 до 999')
    .min(0,'Введите корректное значение')
    .max(999,'Не более 999 дней')
    .required(ValidationMessages.get(ValidationTypes.required)).when('type',(deps,schema) => {
      const type = deps[0];
      if (type === 'prepayment') {
        return schema.notRequired();
      }

      return schema;
    }),
});

interface FormValues {
  contragent_id: number;
  type: 'postpayment' | 'prepayment',
  days: number;
}

const handleInput = (event: React.FormEvent<HTMLInputElement>) => {
  const target = event.target as HTMLInputElement;
  // Используем setTimeout, чтобы обеспечить корректное обновление
  setTimeout(() => {
    // Заменяем точки, запятые и "e"
    target.value = target.value.replace(/[.,e]/g,'');
  },0);
};

interface AddContractProps {
  onClose: () => void;
}

const types = [{ value: 'prepayment',label: 'Предоплата' },{ value: 'postpayment',label: 'Отсрочка платежа' }];

const AddContractModalComponent: FC<AddContractProps> = ({ onClose }) => {
  const { RootStore: { ContractsStore: { contragents,updateDealConfirm } } } = useStore();
  const { handleSubmit,control,watch,setValue } = useForm<FormValues>({ resolver: yupResolver(schema),defaultValues: { type: 'postpayment',days: 0 } });

  const onSubmit = useCallback((args: FormValues) => updateDealConfirm({ ...args,days: args.days ?? 0 },onClose),[onClose,updateDealConfirm]);

  const type = watch('type');

  useEffect(() => {
    if (type === 'prepayment')
      setValue('days',0);
  },[type]);


  return (<Modal title={'Добавить договор'} onClose={onClose} maxWidth='550px' minWidth='480px'>
    <div className={cls['modal-container']}>
      <SelectField control={control} name='contragent_id' label={'Поставщик'} selectProps={{
        placeholder: 'Выбери поставщика',
        fieldNames: { value: 'id',label: 'title' }
      }}
        options={toJS(contragents)} />
      <SelectField control={control} name='type' label={'Условия оплаты'}
        selectProps={{
          placeholder: 'Выбери условие оплаты по договору',
        }} options={types}

      />
      <Input variant={EInputStyleVariant.basicInput} disabled={type === 'prepayment'} control={control} name='days' type='number'
        placeholder='Введи количество дней отсрочки' label='Количество дней отсрочки' inputProps={{ onInput: handleInput }} />
      <div className={cls['modal-buttons']}>
        <ModalButtons onPrimaryButtonClick={handleSubmit(onSubmit)} primaryButtonLabel={'Сохранить'} onSecondaryButtonClick={onClose} secondaryButtonLabel={'Отменить'} />
      </div>
    </div>
  </Modal>);
};

export const AddContractModal = observer(AddContractModalComponent);