import { IOwner } from "pages/spot/spot-page/types";
import { IBaseResponse } from "./api";
import { api } from "./api-new";
import { onError } from "./schedulePlanning";

export async function getOwners(spot_id: number) {
  try {
    const response = await api.get<IBaseResponse<IOwner[]>>(
      "spot/dividends/get",
      {
        params: { spot_id },
      }
    );

    return response.data.data;
  } catch (error) {
    return onError(error);
  }
}

export async function updateDividends(
  spot_id: number,
  owners: { full_name: string; dividends: number }[]
) {
  try {
    const response = await api.post<{ success: boolean }>(
      "spot/dividends/set",
      { spot_id, owners }
    );

    return response.data.success;
  } catch (error) {
    return onError(error);
  }
}
