import { makeAutoObservable } from "mobx";
import { RootStore } from "../../../store/rootStore";
import { getOwners, updateDividends } from "api/spotCard";
import { IOwner } from "./types";
import { showConfirmModal } from "ui-new/alert/confirm";
import { showNotificationModal } from "ui-new/alert";

export class SpotPageStore {
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
  }

  owners: IOwner[] = [];

  fetchGetOwners = async () => {
    const spotId = this.rootStore.HeaderStore.profileData?.spot_id;
    if (typeof spotId !== "number") return;

    const response = await getOwners(spotId);

    if (!response) return;

    this.owners = response;
  };

  updateDividendsConfirm = (
    owners: { full_name: string; dividends: number }[],
    onConfirm: () => void
  ) => {
    showConfirmModal({
      title: "Сохранить изменения?",
      onConfirm: () => this.fetchUpdateDividends(owners).then(onConfirm),
      confirmButtonLabel: "Сохранить",
      minWidth: "350px",
    });
  };

  fetchUpdateDividends = async (
    owners: { full_name: string; dividends: number }[]
  ) => {
    const spotId = this.rootStore.HeaderStore.profileData?.spot_id;
    if (typeof spotId !== "number") return;

    const response = await updateDividends(spotId, owners);

    if (!response) return;

    showNotificationModal({
      title: "Распределение настроено",
      type: "success",
    });
    this.fetchGetOwners();
  };
}
