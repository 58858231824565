import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import cls from './styles.module.scss';
import { Dividends } from './dividends/dividends';
import { useStore } from 'store';
import { showNotificationModal } from 'ui-new/alert';

interface SpotPageProps {

}

const SpotPageComponent: FC<SpotPageProps> = () => {
  const { RootStore: { HeaderStore: { profileData } } } = useStore();
  //TODO: заменить на en title
  if (!profileData || profileData.job_title !== 'Управляющий') {
    showNotificationModal({
      title: 'Ошибка прав доступа',
      errors: ['Не соответствие прав доступа, невозможно выполнить операцию'],
      type: 'failure'
    });
    return null;
  }

  return (<div className={cls['spot__wrapper']}>
    <h2 className={cls['spot__title']}>Карточка спота</h2>
    <Dividends />
  </div>);
};

export const SpotPage = observer(SpotPageComponent);