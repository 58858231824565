import { observer } from 'mobx-react-lite';
import { FC,useEffect,useRef } from 'react';
import { useFieldArray,useForm } from 'react-hook-form';
import { EInputStyleVariant,Input } from 'ui-new/input';
import { Modal } from 'widgets/modal';
import { TextButton } from 'ui-new/text-button';
import { ModalButtons } from 'widgets/modal/buttons';
import cls from './modal-styles.module.scss';
import { useStore } from 'store';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { ValidationMessages,ValidationTypes } from 'assets/utils/validationMessages';
import { BucketIcon } from 'pages/salary-fund/icons';
import { showConfirmModal } from 'ui-new/alert/confirm';

const schema = yup.object({
  owners: yup.array(
    yup.object({
      full_name: yup.string().required(ValidationMessages.get(ValidationTypes.required)),
      dividends: yup.number().typeError(ValidationMessages.get(ValidationTypes.required) as string).required(ValidationMessages.get(ValidationTypes.required))
    })
  ).test({
    message: 'Сумма распределенных долей должна быть 100%',
    test: (arr) => {
      const dividends = arr?.reduce((acc,val) => acc + (Number(val.dividends) ?? 0),0);
      return dividends === 100;
    }
  }).required()
});

const onDividendsInput = (e: React.FormEvent<HTMLInputElement>) => {
  const value = (e.target as HTMLInputElement).value;
  if (!/^\d*\.?\d{0,2}$/.test(value)) {
    (e.target as HTMLInputElement).value = value.slice(0,-1);
  }
};

interface FormValues {
  owners: {
    full_name: string;
    dividends: number;
  }[];
};

interface EditDividendsModalProps {
  onClose: () => void;
}

const EditDividendsModalComponent: FC<EditDividendsModalProps> = ({ onClose }) => {
  const { RootStore: { SpotPageStore: { owners,updateDividendsConfirm } } } = useStore();

  const { control,handleSubmit,getValues,watch,formState: { errors } } = useForm<FormValues>({
    resolver: yupResolver(schema),
    defaultValues: { owners: owners.map(o => ({ ...o,dividends: Math.round(o.dividends * 100 * 100) / 100 })) }
  });
  const { fields,append,remove } = useFieldArray({ control,name: 'owners' });
  const arr = watch('owners');
  const dividendsCount = arr.reduce((acc,val) => acc + (Number(val.dividends) ?? 0),0);

  const onSubmit = (values: FormValues) => {
    updateDividendsConfirm(values.owners.map(o => ({ ...o,dividends: o.dividends / 100 })),onClose);
  };

  const confirmClose = () => {
    showConfirmModal({
      title: 'Закрыть окно настройки?',messages: ['Внесенные изменения не будут сохранены'],onConfirm: onClose,
      confirmButtonLabel: 'Закрыть',minWidth: '400px'
    });
  };

  useEffect(() => {
    if (getValues().owners.length === 0) {
      append({ full_name: '',dividends: 0 });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[watch('owners')]);

  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const div = containerRef.current;

    if (!div) return;


    const onResizeHandler = (e: UIEvent) => {
      div.style.maxHeight = `${window.innerHeight * 0.7}px`;
    };

    window.addEventListener('resize',onResizeHandler);
    return () => window.removeEventListener('resize',onResizeHandler);
  },[]);

  return (
    <Modal title={'Настройка долей'} onClose={confirmClose} maxWidth='650px' minWidth='650px'>
      <div className={cls['form']}>
        <div className={cls['owners-container']} ref={containerRef} style={{ maxHeight: `${window.innerHeight * 0.7}px` }}>
          {fields.map((fields,index,arr) => (
            <div className={cls['owner']}>
              <div className={cls['owner-header']}>
                <h5>№ {index + 1}</h5>
                <button className={cls['owner-delete']} disabled={arr.length === 1} onClick={() => remove(index)}>
                  <BucketIcon color={arr.length !== 1 ? '#EB5757' : undefined} /> Удалить
                </button>
              </div>
              <div className={cls['owner-fields']}>
                <Input key={fields.full_name + fields.id} variant={EInputStyleVariant.basicInput} type='text' label='ФИО финпартнера'
                  placeholder='Введи Фамилию и Имя финпартнера' control={control} name={`owners.${index}.full_name`} />
                <Input key={fields.dividends + fields.id} variant={EInputStyleVariant.basicInput} type='number' label='Доля, %'
                  placeholder='0,00' control={control} name={`owners.${index}.dividends`} inputProps={{ onInput: onDividendsInput }} />
              </div>
            </div>
          ))}
        </div>
        <div className={cls['info-container']}>
          <p className={cls['info-count']}>Сумма долей <span style={dividendsCount > 100 ? { color: '#eb5757' } : {}}>{Math.floor(dividendsCount * 100) / 100}</span>%</p>
          <p className={cls['info-error']}>{errors.owners?.root?.message}</p>
        </div>
        <div className={cls['buttons-group']}>
          {dividendsCount < 100 ? <TextButton onClick={() => append({ full_name: '',dividends: 0 })}>Добавить</TextButton> : <div></div>}
          <ModalButtons onPrimaryButtonClick={handleSubmit(onSubmit,(err) => { console.log(err); })} primaryButtonLabel={'Сохранить'} onSecondaryButtonClick={onClose} secondaryButtonLabel={'Отменить'} />
        </div>
      </div>
    </Modal>
  );
};

export const EditDividendsModal = observer(EditDividendsModalComponent);